<template>
  <div class="distribution_management" style="padding: 5px 10px 10px 10px">
    <div class="header">
      {{ $t("add_distribution.add_distribution") }}
    </div>
    <div v-if="!isLoading" class="Container Flipped">
      <table class="Content">
        <tr>
          <th>{{ $t("admin.title") }}</th>
          <th>{{ $t("admin.description") }}</th>
          <th>{{ $t("admin_sidebar.video") }}</th>
          <th>{{ $t("add_distribution.icon") }}</th>
          <th>{{ $t("admin.image") }}</th>
          <th>{{ $t("admin.edit") }}</th>
        </tr>
        <tr v-for="(distribution, index) in allDistribution" :key="index">
          <td>
            <div v-if="lang == 'ka'" v-html="distribution.titleKA"></div>
            <div v-else v-html="distribution.titleEN"></div>
          </td>
          <td>
            <div
              v-if="lang == 'ka'"
              :ref="'descriptionKA' + index"
              class="short_text"
              style="padding-left: 20px"
            ></div>
            <div
              v-else
              :ref="'descriptionEN' + index"
              class="short_text"
              style="padding-left: 20px"
            ></div>
          </td>
          <td>
            <iframe
              v-if="distribution.video != 'null'"
              width="200"
              height="100"
              :src="distribution.video"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </td>
          <td>
            <img
              v-if="distribution.icon != 'null'"
              style="height: 50px; width: 50px"
              :src="server_url + '/' + distribution.icon"
              alt=""
            />
          </td>
          <td>
            <hooper
              v-if="distribution.images != 'null'"
              :progress="true"
              :autoPlay="true"
              :playSpeed="3000"
              :hover="true"
              style="border-radius: 0px"
            >
              <slide v-for="item in distribution.images" :key="item._id">
                <div class="conatiner" style="height: 100px; width: 150px">
                  <img
                    style="height: 100px; width: 150px"
                    :src="`${server_url}/${item.image}`"
                    :alt="item"
                  />
                </div>
              </slide>
              <hooper-navigation slot="hooper-addons"></hooper-navigation>
              <hooper-pagination slot="hooper-addons"></hooper-pagination>
            </hooper>
          </td>
          <td>
            <b-button
              @click="
                (modalShow = !modalShow),
                  editDistribution(
                    distribution._id,
                    distribution.descriptionKA,
                    distribution.descriptionEN,
                    distribution.images,
                    distribution.titleKA,
                    distribution.titleEN,
                    distribution.icon,
                    distribution.video
                  )
              "
            >
              <i class="fal fa-pen"></i>
            </b-button>
          </td>
        </tr>
      </table>
    </div>
    <div v-else>
      <b-skeleton-table
        :rows="10"
        :columns="4"
        :table-props="{ bordered: true, striped: true }"
      ></b-skeleton-table>
    </div>

    <b-modal id="modal-xl-2" v-model="modalShow" size="xl" hide-footer>
      <div class="text_editor">
        <label for="">{{ $t("admin.title") }} (KA)</label>
        <vue-editor v-model="update.titleKA"></vue-editor>
        <label for="">{{ $t("admin.title") }} (EN)</label>
        <vue-editor v-model="update.titleEN"></vue-editor>
      </div>
      <div class="text_editor">
        <label for="">{{ $t("admin.description") }} (KA)</label>
        <vue-editor v-model="update.descriptionKA"></vue-editor>
        <label for="">{{ $t("admin.description") }} (EN)</label>
        <vue-editor v-model="update.descriptionEN"></vue-editor>
      </div>
      <div class="text_editor">
        <label for="">{{ $t("admin_sidebar.video") }}</label>
        <input v-model="update.video" />
      </div>
      <label for=""> {{ $t("add_distribution.icon") }}</label>
      <div>
        <img
          style="width: 150px; height: 100px"
          :src="`${server_url}/${update.image}`"
          alt="await for new image to upload"
          class="image"
        />
      </div>
      <input
        type="file"
        style="margin: 10px 0"
        accept="image/jpg, image/png, image/jpeg"
        @change="previewFilesIcon($event)"
      />
      <hooper
        style="width: 100%"
        class="hooper_edit"
        :itemsToShow="3"
        :itemsToSlide="3"
      >
        <slide style="width: 160px" v-for="item in images" :key="item._id">
          <div class="upload_image">
            <img
              :src="`${server_url}/${item.image}`"
              alt="await for new image to upload"
              class="image"
            />
            <div class="overlay">
              <div class="icon">
                <label for="fileInput">
                  <i class="fas fa-pen mr-3"></i>
                </label>
                <input
                  type="file"
                  accept="image/jpg, image/png, image/jpeg"
                  multiple
                  id="fileInput"
                  class="input"
                  @change="editImage($event, item._id)"
                />
                <i
                  class="fas fa-trash"
                  @click="
                    deleteImage(
                      item._id,
                      update.descriptionKA,
                      update.descriptionEN,
                      update.titleKA,
                      update.titleEN,
                      update.icon,
                      update.video
                    )
                  "
                ></i>
              </div>
            </div>
          </div>
        </slide>
        <hooper-navigation slot="hooper-addons"></hooper-navigation>
        <hooper-pagination slot="hooper-addons"></hooper-pagination>
      </hooper>
      <input
        type="file"
        style="margin: 10px 0"
        accept="image/jpg, image/png, image/jpeg"
        multiple
        @change="previewFiles($event)"
      />
      <button
        type="submit"
        class="submit btn btn-primary"
        @click="UpdateDistribution()"
      >
        <span v-show="!isLoading">{{ $t("admin.edit") }}</span>
        <span v-show="isLoading">
          {{ $t("login.loading") }}
          <spinner v-show="isLoading" text="loading" />
        </span>
      </button>
    </b-modal>
  </div>
</template>

<script>
import {
  Hooper,
  Slide,
  Navigation as HooperNavigation,
  Pagination as HooperPagination,
} from "hooper";
import "hooper/dist/hooper.css";
import axios from "axios";
import env from "../../../env.json";
import { VueEditor } from "vue2-editor";
import spinner from "../../spinner/Spinner.vue";
import Swal from "sweetalert2";
export default {
  name: "getDistribution",
  components: {
    VueEditor,
    Hooper,
    Slide,
    HooperNavigation,
    HooperPagination,
    spinner,
  },
  data() {
    return {
      modalShow: false,
      allDistribution: [],
      image_id: "",
      images: null,
      edit_image: null,
      update: {
        _id: "",
        descriptionKA: "",
        descriptionEN: "",
        video: null,
        icon: null,
        titleKA: "",
        titleEN: "",
      },
      server_url: env.server_url,
      isLoading: false,
      lang: "ka",
    };
  },
  mounted() {
    this.getDistribution();
    if (localStorage.getItem("lang") == "ka") {
      this.lang = "ka";
    } else {
      this.lang = "en";
    }
  },
  methods: {
    previewFiles(e) {
      const image = e.target.files;
      if (e.target.files[0].size > 1000000) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: this.$t("errors.too_long"),
        });
      }
      this.images = image;
    },
    editImage(e, id) {
      const image = e.target.files;
      if (e.target.files[0].size > 1000000) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: this.$t("errors.too_long"),
        });
      }
      this.edit_image = image;
      this.image_id = id;
    },
    previewFilesIcon(e) {
      const image = e.target.files[0];
      if (e.target.files[0].size > 1000000) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: this.$t("errors.too_long"),
        });
      }
      this.createBase64Image(image);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.update.icon = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },
    getDistribution() {
      this.isLoading = true;
      axios.get(`${env.host}/get/informations/distribution`).then((res) => {
        this.isLoading = false;
        res.data.item.map((distribution, index) => {
          this.$nextTick(() => {
            this.$refs["descriptionKA" + index].forEach((element) => {
              element.insertAdjacentHTML(
                "beforeend",
                distribution.descriptionKA
              );
            });
          });
          this.$nextTick(() => {
            this.$refs["descriptionEN" + index].forEach((element) => {
              element.insertAdjacentHTML(
                "beforeend",
                distribution.descriptionEN
              );
            });
          });
          this.update._id = distribution._id;
        });
        this.allDistribution = res.data.item;
      });
    },
    editDistribution(
      id,
      descriptionKA,
      descriptionEN,
      images,
      titleKA,
      titleEN,
      icon,
      video
    ) {
      this.update._id = id;
      this.update.descriptionKA = descriptionKA;
      this.update.descriptionEN = descriptionEN;
      this.images = images;
      this.update.titleKA = titleKA;
      this.update.titleEN = titleEN;
      this.update.icon = icon;
      this.update.video = video;
    },
    UpdateDistribution() {
      this.isLoading = true;
      if (
        this.update.video == null &&
        this.images == null &&
        this.update.icon == null
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: this.$t("errors.enter_parameters"),
        });
        this.isLoading = false;
      }
      if (!this.update.icon.includes("data:image/")) {
        this.update.icon = null;
      }
      axios
        .post(
          `${env.host}/edit/informations/distribution/byImages`,
          this.update
        )
        .then(() => {
          Swal.fire({
            icon: "success",
            text: this.$t("success.success_edit"),
          });
          this.isLoading = false;
          this.modalShow = false;
          setTimeout(() => {
            this.getDistribution();
          }, 1000);
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
      if (!Array.isArray(this.images)) {
        for (let i = 0; i < this.images?.length; i++) {
          const formData = new FormData();
          formData.append("image", this.images[i]);
          axios
            .post(
              `${env.host}/add/image/distribution/${this.update._id}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then(() => {
              Swal.fire({
                icon: "success",
                text: this.$t("success.success_edit"),
              });
              this.isLoading = false;
              this.modalShow = false;
              setTimeout(() => {
                this.getDistribution();
              }, 1000);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
      if (this.edit_image !== null) {
        this.images = this.edit_image;
        for (let i = 0; i < this.images?.length; i++) {
          const formData = new FormData();
          formData.append("image", this.images[i]);
          axios
            .post(
              `${env.host}/edit/image/distributon/${this.update._id}/${this.image_id}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then(() => {
              Swal.fire({
                icon: "success",
                text: this.$t("success.success_edit"),
              });
              this.isLoading = false;
              this.modalShow = false;
              setTimeout(() => {
                this.getDistribution();
              }, 1000);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
    deleteImage(
      id,
      descriptionKA,
      descriptionEN,
      titleKA,
      titleEN,
      icon,
      video
    ) {
      this.isLoading = true;
      axios
        .delete(
          `${env.host}/delete/image/distribution/${this.update._id}/${id}`
        )
        .then(() => {
          axios.get(`${env.host}/get/informations/distribution`).then((res) => {
            res.data.item.map((item) => {
              this.editDistribution(
                this.update._id,
                descriptionKA,
                descriptionEN,
                item / images,
                titleKA,
                titleEN,
                icon,
                video
              );
              this.isLoading = false;
            });
          });
        });
    },
  },
};
</script>
<style scoped>
.distribution_management {
  width: 100%;
}
label {
  font-family: mtavruliBOLD;
  margin-top: 15px;
}
.hooper_edit {
  background: #f2f5fa;
  padding: 10px;
  border-radius: 0px;
  width: 100% !important;
  height: 150px !important;
}
#fileInput {
  display: none;
}
.upload_image {
  position: relative;
}
.image {
  display: block;
  width: 150px;
  height: 100px;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  width: 150px;
  opacity: 0;
  transition: 0.3s ease;
  background-color: #21598e9d;
}
.upload_image:hover .overlay {
  opacity: 1;
}
.icon {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.icon i {
  cursor: pointer;
  transition: 0.2s;
}
.icon i:hover {
  transition: 0.2s;
  color: #dadada;
}
::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb {
  background: #7e5493;
  border-radius: 0px;
}
.Container {
  width: 200;
  overflow-y: auto;
}
.Flipped,
.Flipped .Content {
  transform: rotateX(180deg);
  -ms-transform: rotateX(180deg); /* IE 9 */
  -webkit-transform: rotateX(180deg); /* Safari and Chrome */
}
.hooper {
  width: 150px;
  height: 100px;
  border-radius: 0px;
}
.short_text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
button,
.header {
  background: #7e5493;
  border: none;
  padding: 10px 30px 10px 30px;
  border-radius: 0px;
}
.header {
  color: white;
  width: 25%;
  margin-bottom: 10px;
}
i {
  color: white;
}
table {
  /* table-layout: fixed; */
  width: 100%;
  border-spacing: 0;
  border-radius: 0px;
  overflow: hidden;
}
thead {
  visibility: hidden;
  position: absolute;
  width: 0;
  height: 0;
}
th {
  background: #7e5493;
  color: #fff;
}
td:nth-child(1) {
  background: #7e5493;
  color: #fff;
  border-radius: 0px0;
}
th,
td {
  padding: 10px;
}
tr,
td {
  display: block;
}
td {
  position: relative;
}
td::before {
  content: attr(data-label);
  position: absolute;
  left: 0;
  padding-left: 1em;
  font-weight: 600;
  font-size: 0.9em;
  text-transform: uppercase;
}
tr {
  margin-bottom: 1.5em;
  border: 1px solid #ddd;
  border-radius: 0px;
  text-align: right;
}
tr:last-of-type {
  margin-bottom: 0;
}
td:nth-child(n + 2):nth-child(odd) {
  background-color: #ddd;
}
@media only screen and (min-width: 768px) {
  thead {
    visibility: visible;
    position: relative;
  }
  th {
    text-align: left;
    text-transform: uppercase;
    font-size: 0.9em;
  }
  tr {
    display: table-row;
    border: none;
    border-radius: 0;
    text-align: left;
  }
  tr:nth-child(even) {
    background-color: #ddd;
  }
  td {
    display: table-cell;
  }
  td::before {
    content: none;
  }
  td:nth-child(1) {
    background: transparent;
    color: #444;
    border-radius: 0;
  }
  td:nth-child(n + 2):nth-child(odd) {
    background-color: transparent;
  }
}
input {
  border-radius: 0px;
  width: 100%;
  border: 1px solid #cccccc;
  margin-bottom: 20px;
  height: 40px;
  padding-left: 10px;
}
.submit {
  width: 100%;
  height: 45px;
  border: none;
  background: royalblue;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  color: white;
}
</style>